import React, {
    Component
} from 'react';
import './ResultsPage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';

import { SearchPage } from '../SearchPage/SearchPage.js';

import OptionBar from '../../components/OptionBar/OptionBar.js';
import SideBar from '../../components/SideBar/SideBar.js';
//import EditPane from '../../components/EditPane/EditPane.js';
import SearchResult from '../../components/SearchResult/SearchResult.js';
import DragDropSection from '../../components/DragDropSection/DragDropSection.js';
import ResultsSearchBar from '../../components/ResultsSearchBar/ResultsSearchBar.js';
import ReportPageResult from '../../components/ReportPageResult/ReportPageResult.js';
import ReportModal from '../../components/ReportModal/ReportModal.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroller';
import Switch from 'react-switch';
import { DndProvider } from 'react-dnd';
import { HTML5Backend}  from 'react-dnd-html5-backend';
import { ErrorPage } from '../ErrorPage/ErrorPage';

class ResultsPage extends Component {

    constructor(props) {
        super(props);
        if (!(this.props.urlAccessed)) {
            this.state = {
                searchResults: [],
                searchResultsID: null,
                reportResults: [],
                reportResultsObjects: {},
                sidebarImgUrl: this.props.sidebarImgUrl ?? null,
                imgURL: this.props.imgUrl ?? null,
                imgValue: this.props.imgValue ?? null,
                maskImgUrl: this.props.maskImgUrl ?? null,
                processedImageData: null,
                processImageSuccess: false,
                brightness: this.props.brightness,
                saturation: this.props.saturation,
                contrast: this.props.contrast,
                reset: false,
                hasMore: true,
                isLoading: false,
                infoGathered: true,
                pageNum: 1,
                resultSet: 0,
                resultSize: 180,
                searchInfo: null,
                currentPage: 'showResults',
                runningAPI: true,
                reportGen: false,
                reportNotesMax: 250,
                imageChecked: true,
                locationChecked: true,
                keywordsChecked: true,
                notesChecked: true,
                hotelOrder: [],
                filterActive: false,
                filterQuery: null,
                editImage: false,
                urlAccessed: false,
                keywords: this.props.keywords,
                filterKeywords: this.props.filterKeywords,
                location: this.props.location,
                coordinates: this.props.coordinates,
                mapZoom: this.props.mapZoom,
                mapBounds: this.props.mapBounds,
                resultNotFound: false,
                activeResultModal: null,
                abortedRequest: false
            };
        } else {
            this.state = {
                searchResults: [],
                reportResults: [],
                reportResultsObjects: {},
                sidebarImgUrl: null,
                imgURL: null,
                maskImageUrl: null,
                imgValue: null,
                brightness: 100,
                contrast: 100,
                saturation: 100,
                reset: false,
                hasMore: true,
                isLoading: false,
                infoGathered: false,
                pageNum: 1,
                resultSet: 0,
                resultSize: 180,
                searchInfo: null,
                currentPage: 'showResults',
                runningAPI: true,
                reportGen: false,
                reportNotesMax: 250,
                imageChecked: true,
                locationChecked: true,
                keywordsChecked: true,
                notesChecked: true,
                hotelOrder: [],
                filterActive: false,
                filterQuery: null,
                urlAccessed: true,
                keywords: null,
                filterKeywords: null,
                coordinates: null,
                location: null,
                mapZoom: null,
                mapBounds: null,
                resultNotFound: false,
                processedImageData: null,
                abortedRequest: false
            }
        }

    }

    // This is used for cancelling pending requests when update search is called
    controller = new AbortController();

    cancelFetch(){
        this.controller.abort();
    }
    
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    async getProcessedImage() {
        if (!this.state.imgURL || !this.state.maskImgUrl) {
            console.log("No image or mask URL provided, skipping processing.");
            return null;
        }

        let data = new FormData();
        let maskedImg = this.dataURLtoFile(this.state.imgURL, 'maskedimg.png');
        let mask = this.dataURLtoFile(this.state.maskImgUrl, 'mask.png');
        data.append('masked_image', maskedImg);
        data.append('mask', mask);

        const infillResponse = await fetch(`/api/infill_image?infill=true`, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
            },
        }).catch((error) => {
            this.setState({ processedImageSuccess: false })
            console.log(error);
        });

        return await infillResponse.json();
    }
    
    async loadData(just_filter=false) {
        // This helps keep infinite scroll from firing requests faster than we can process them
        if (!this.state.isLoading && this.state.mapBounds) {
            this.setState({ isLoading: true });
            let passed = true;
            // encode things just in case
            let encodedSearchID = encodeURI(this.props.searchID);

            let data;
            if(!this.state.processedImageData) {
                 data = await this.getProcessedImage();
                 this.setState({
                     processedImageData: data
                 });
            } else {
                data = this.state.processedImageData;
            }
            
            let searchResponse;
            let content;
            if (just_filter == true){
                // right here, searchResponse is the old results
                console.log('just filter instead of full search');

                const requestBody = {
                    original_results: this.state.searchResults,
                    filter_keywords: this.state.filter_keywords,
                    coordinates: this.state.coordinates,
                    map_zoom: this.state.mapZoom,
                    keywords: this.state.keywords,
                    infilled_image_url: this.state.infilled_image_url,
                    searchID: this.state.searchID
                };

                searchResponse = await fetch(`/api/filter_by_keyword`, {
                    method: 'POST',
                    signal: this.controller.signal,
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }).catch((err) => {
                    // Handle errors and update the state accordingly
                    console.log(err);
                    this.setState({ infoGathered: true, runningAPI: false, isLoading: false });
                });

                if (searchResponse.ok) {
                    content = await searchResponse.json();
                    for (var i=0; i<100; i++) {
                        console.log(i)
                        console.log(content['images'][i]['name'])
                        console.log(content['images'][i]['score_breakdown']['filter_text']);
                    }
                }
            }
            else {
                console.log('running full search')

                // Prepare the request body with image fields set to null by default
                const requestBody = {
                    keywords: this.state.keywords,
                    filter_keywords: this.state.filter_keywords,
                    coordinates: this.state.coordinates,
                    location: this.state.location,
                    update: this.state.updated,
                    map_zoom: this.state.mapZoom,
                    map_bounds: this.state.mapBounds,
                    n_results: this.state.resultSize,
                    offset: this.state.resultSet * this.state.resultSize,
                    masked_image_url: null,
                    mask_url: null,
                    infilled_image_url: null
                };

                // Only overwrite image-related fields if data is available
                if (data && data.masked_image && data.mask && data.infilled_image) {
                    requestBody.masked_image_url = data.masked_image;
                    requestBody.mask_url = data.mask;
                    requestBody.infilled_image_url = data.infilled_image;
                }
                searchResponse = await fetch(`/api/search`, {
                    method: 'POST',
                    signal: this.controller.signal,
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }).catch((err) => {
                    // Handle errors and update the state accordingly
                    console.log(err);
                    this.setState({ infoGathered: true, runningAPI: false, isLoading: false });
                });

                if (searchResponse.ok) {
                    content = await searchResponse.json();
                }
                
            }

            if (passed) {
                // const content = await searchResponse.json();
                console.log(content);

                let results = content.images;
                let load = true;

                // TODO: evaluate if we need to shallow copy the entire results array,
                // or if we can just store the results of .length()
                let copiedOld = this.state.searchResults.slice();
                // let combinedResults = copiedOld.concat(results);
                let combinedResults = results;

                // we cut off inifinite scroll at 1000 for memory and cache restraints
                // OR
                // until no more new results are returned from the API
                if (combinedResults.length >= window.maxSearchResults || results.length === 0) {
                    load = false;
                }

                //update our URL to include search id
                //using result set hack as a janky fix to keep id from changing via infinite scroll
                if(this.state.resultSet === 0){
                    let search_id = content.search_id;
                    window.history.replaceState({}, 'results', `/results/${search_id}`);
                    this.setState({
                        searchResultsID: search_id,
                        sidebarImgUrl: content.image_path,
                    });
                }
                this.setState({
                    searchResults: combinedResults,
                    pageNum: this.state.pageNum + 1,
                    resultSet: this.state.resultSet + 1,
                    hasMore: load,
                    isLoading: false,
                    runningAPI: true
                });
            }
        }

        return;
    }

    async getSearchInfo() {
        // This helps keep infinite scroll from firing requests faster than we can process them
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
            let passed = true;

            // URI encode search ID just in case
            let encodedSearchID = encodeURI(this.props.searchID);
            const searchResponse = await fetch(`/api/search/${encodedSearchID}`, {
                method: 'POST',
                body: JSON.stringify({
                    image: null
                }),

                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).catch(() => {
                passed = false;
                this.setState({ searchInfo: null, runningAPI: false })
            });

            if (passed) {
                try {
                    const retrievedSearchInfo = await searchResponse.json();
                    let results = retrievedSearchInfo.images;

                    let load = true;

                    let copiedOld = this.state.searchResults.slice();
                    let combinedResults = copiedOld.concat(results);

                    // we cut off inifinite scroll at 1000 for memory and cache restraints
                    // OR
                    // until no more new results are returned from the API
                    if (combinedResults.length >= 1000 || results.length === 0) {
                        load = false;
                    }


                    this.setState({
                        keywords: retrievedSearchInfo.keywords,
                        location: retrievedSearchInfo.location,
                        coordinates: retrievedSearchInfo.coordinates,
                        mapZoom: retrievedSearchInfo.map_zoom,
                        searchInfo: retrievedSearchInfo,
                        imgURL: retrievedSearchInfo.image_path,
                        processedImageData: {
                            infilled_image: retrievedSearchInfo.image_path,
                            masked_image: null,
                            mask: null,
                        },
                        infoGathered: true,
                        searchResults: combinedResults,
                        pageNum: this.state.pageNum + 1,
                        resultSet: this.state.resultSet + 1,
                        hasMore: load,
                        runningAPI: true,
                        isLoading: false,
                    });
                } catch (error) {
                    this.setState({ resultNotFound: true });
                }

            }
        }

    }


    mapResults(resultsList, filter = null) {
        // // if the user is filtering results, only include relevant results from the filter query
        // if (filter) {
        //     resultsList = resultsList.filter(result => result.name.toLowerCase().includes(filter.toLowerCase()));
        // }

        return resultsList.map((x, i) => {
            let prevID = null;
            let nextID = null;
            if (i !== 0) {
                prevID = `${resultsList[i - 1].id}`;
            }

            if (i !== resultsList.length - 1) {
                nextID = `${resultsList[i + 1].id}`;
            }

            let id = x.id;
            return (
                <SearchResult
                    imgUrl={x.path}
                    hotelID={x.hotel_id}
                    hotelName={x.name}
                    id={x.id}
                    room={x.room}
                    score={(x.score ? x.score : null)}
                    scoreBreakdown={(x.score_breakdown ? x.score_breakdown : null)}
                    prevID={prevID}
                    nextID={nextID}
                    toggleModal = {this.toggleModal.bind(this)}
                    hotelLocation={[x.lat, x.lng]}
                    key={`${x.id}-${i}`} rem={() => this.clickHandler(id)}
                    inReport={(id) => {
                        return this.state.reportResults.includes(id);
                    }}
                    addReportResult={() => {
                        this.addReportResult({
                            'id': id,
                            'hotel_name': x.name,
                            'hotel_id': x.hotel_id,
                            'image_path': x.path,
                            'room': x.room
                        });
                    }}
                    panelAddReportResult={(imageObject) => {
                        this.addReportResult({
                            'id': imageObject.id,
                            'hotel_name': imageObject.name,
                            'hotel_id': imageObject.hotel_id,
                            'image_path': imageObject.path,
                            'room': imageObject.room
                        });
                    }}
                />
            );
        });
    }

    clickHandler(id) {
        // let currentResults = this.state.results;
        // currentResults = currentResults.splice(currentResults.indexOf(id), 1);
    }

    async addReportResult(imageObject) {
        let id = imageObject.id;

        let start = new Date();

        var curResults = [...this.state.reportResults];
        let curResultsObj = this.state.reportResultsObjects;
        let currentHotelOrder = this.state.hotelOrder;

        if (!(currentHotelOrder.includes(imageObject.hotel_name))) {
            currentHotelOrder.push(imageObject.hotel_name);
        }

        if (curResults.includes(id)) {
            var index = curResults.indexOf(id);
            curResults.splice(index, 1);
            delete curResultsObj[imageObject.hotel_name][imageObject.id];
            let obj = curResultsObj[imageObject.hotel_name];
            if (Object.entries(obj).length === 0 && obj.constructor === Object) {
                delete curResultsObj[imageObject.hotel_name]
            }

        }
        else {
            curResults.push(id);
            if (!(imageObject.hotel_name in curResultsObj)) {
                curResultsObj[imageObject.hotel_name] = {};
            }
            curResultsObj[imageObject.hotel_name][imageObject.id] = imageObject;
        }


        this.setState({
            reportResults: curResults,
            reportResultsObjects: curResultsObj,
            hotelOrder: currentHotelOrder
        });
    }

    deconstructReport() {
        for (let i = 0; i < this.state.reportResults.length; i++) {
            let elem = this.state.reportResults[i];

            let possibleTarget = document.getElementById(`more-images-elem-report-add-${elem}`);

            if (possibleTarget) {
                possibleTarget.classList.toggle('active');
            }

            possibleTarget = document.getElementById(`select-${elem}`);

            if (possibleTarget) {
                possibleTarget.classList.toggle('active');
            }

        }

        this.setState({ reportResults: [], reportResultsObjects: {}, hotelOrder: [] });
    }

    updateSearch(updateObj) {
        //cancel any outstanding requests
        this.cancelFetch();
        
        // This is related to a toggling process for switching between the hotel img and the loadering circle
        // Needs better implementation. Breaks w/o this b/c its written to begin with this toggled as hidden and the loader toggled as visible
        let sidebarImageWrapper = document.getElementById('uploaded-entry-wrapper');
        if (sidebarImageWrapper){
            sidebarImageWrapper.classList.toggle('hide');
        }
        //clear any search filter currently active.
        //needs to be encapsulated in a function. currently duplicating
        // document.getElementById('results-search-bar-input').value = '';
        //                             this.setState({
        //                                 filterActive: false,
        //                                 filterQuery: null
        //                             });

        let keywords = updateObj.keywords ? updateObj.keywords : this.state.keywords;
        let coordinates = updateObj.coordinates ? updateObj.coordinates : this.state.coordinates;
        let location = updateObj.location ? updateObj.location : this.state.location;
        let mapZoom = updateObj.zoom ? updateObj.zoom : this.state.mapZoom;
        let mapBounds = updateObj.bounds ? updateObj.bounds : this.state.mapBounds;
        let filterKeywords = document.getElementById('results-search-bar-input').value ? document.getElementById('results-search-bar-input').value : this.state.filterKeywords;

        //setting resultSet: 0 here is triggering getData() to set a new search id and URL.
        //needs refactoring.
        this.deconstructReport();
        this.setState({
            editImage: false,
            updated: true,
            infoGathered: false,
            searchResults: [],
            //~ sidebarImgUrl: sidebarImgUrl,
            //~ imgURL: imgUrl,
            //~ imgValue: imgValue,
            urlAccessed: false,
            reportResults: [],
            reportResultsObjects: {},
            hasMore: true,
            searchInfo: null,
            pageNum: 1,
            resultSet: 0,
            keywords: keywords,
            filterKeywords: filterKeywords,
            coordinates: coordinates,
            location: location,
            mapZoom: mapZoom,
            mapBounds: mapBounds
        });
    }

    //this opens and closes the results modals
    toggleModal(resultID= null){

        //get our elements
        let activeWrapper = document.getElementById(`result-wrapper-${this.state.activeResultModal}`);
        let activePanel = document.getElementById(`${this.state.activeResultModal}-panel`);
        let activeShifter = document.getElementById(`${this.state.activeResultModal}-shifter`);
        let selectedWrapper = document.getElementById(`result-wrapper-${resultID}`);
        let selectedPanel = document.getElementById(`${resultID}-panel`);
        let selectedShifter = document.getElementById(`${resultID}-shifter`);

        console.log(resultID)
        console.log(this.state.activeResultModal)
        console.log(selectedWrapper)    
        if(resultID && resultID != this.state.activeResultModal){
            
            if (activeWrapper && activePanel && activeShifter) {
                //hide the currently active modal
                activeWrapper.classList.toggle('focus');
                activePanel.classList.toggle('show');
                activeShifter.classList.toggle('show');
            }

            if (selectedWrapper && selectedPanel && selectedShifter){
                //activate the newly selected modal
                selectedWrapper.classList.toggle('focus');
                selectedPanel.classList.toggle('show');
                selectedShifter.classList.toggle('show');

                //scroll into place
                try {
                    document.getElementById('show-results-pane').scrollTop = selectedWrapper.offsetTop - 10;
                } catch(err) {
                    console.log(err);
                }
                //not sure what this does but its a leftover from old code. Not tryna break anything. Try deleting this sometime.
                //~ var evt = new Event("keydown", { "bubbles": true, "cancelable": false });
                //~ selectedPanel.dispatchEvent(evt);
            }
            //record new selection in component state
            this.setState({
                activeResultModal: resultID,
            });
        } else {
            if (activeWrapper && activePanel && activeShifter) {
                // method passed null as argument
                // close active modal
                activeWrapper.classList.toggle('focus');
                activePanel.classList.toggle('show');
                activeShifter.classList.toggle('show');

                this.setState({
                    activeResultModal: null,
                });
            };
        };
    };

            
    componentDidUpdate() {
        if (document.getElementById('report-map')) {
            var map = null;

            // because sometimes setState is slow and we need to force variables
            if (!(this.state.coordinates) && !(this.state.mapZoom)) {
                map = new window.google.maps.Map(document.getElementById('report-map'), {
                    center: { lat: parseFloat(this.state.coordinates[0]), lng: parseFloat(this.state.coordinates[1]) },
                    zoom: this.state.mapZoom,
                    fullscreenControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                    draggable: false
                });
            } else {
                map = new window.google.maps.Map(document.getElementById('report-map'), {
                    center: { lat: this.state.coordinates[0], lng: this.state.coordinates[1] },
                    zoom: this.state.mapZoom,
                    fullscreenControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                    draggable: false
                });
            }
        }

    }

    triggerDataLoad() {
        this.loadData(true);
    }
    
    render() {
        
        if (this.state.reset) {
            window.history.pushState({}, 'search', '/search');
            return <SearchPage />;
        }

        if (this.state.resultNotFound) {
            return <ErrorPage status={404} />;
        }

        let pageContents;
        let reportGen = ``;
        let sidebarImage = this.state.sidebarImgUrl;
        let infiniteScroller;
        let queriedResults
        

        let resultsEnd = (
            <div className="results-end">
                End of Results
            </div>
        );

        const loader = (
            <FontAwesomeIcon icon="circle-notch"
                className="fa-spin load-search" key={0} />
        );


        // this lets us know if there are more images to be gathered. In other words, should we make another request
        // to the backend to get more images
        let hasMore = this.state.hasMore;
        let isLoading = this.state.isLoading;

        let apiDownSplash = (
            <div className="api-down-msg">
                <h2>API appears to be down.</h2>
                <h3 onClick={() => {
                    document.getElementById('api-down-loader').classList.toggle('show');
                    setTimeout(() => {

                        document.getElementById('api-down-loader').classList.toggle('show');
                    }, 1000);

                }}>
                    Try again?
                        <span id="api-down-loader">
                        <FontAwesomeIcon icon="circle-notch"
                            className="fa-spin" />
                    </span>
                </h3>

            </div>
        );

        // if the report page is toggled
        if (this.state.reportGen) {
            reportGen = (
                <ReportModal
                    coordinates={this.state.coordinates}
                    mapZoom={this.state.mapZoom}
                    reportResults={this.state.reportResults}
                    reportResultsObjects={this.state.reportResultsObjects}
                    hotelOrder={this.state.hotelOrder}
                    searchInfo={this.state.searchInfo}
                    sidebarImgUrl={this.state.sidebarImgUrl}
                    location={this.state.location}
                    keywords={this.state.keywords}
                    reportNotesMax={this.state.reportNotesMax}
                    reportGenExit={() => this.setState({ reportGen: false })}
                />);
        }

        // if this page was accessed via a URL (instead of being accessed by creating a new search)
        if (this.state.urlAccessed) {

            // init variables

            // if we haven't gathered initial search info from the backend
            if (!(this.state.infoGathered)) {

                // set the sidebar image to null while we for the actual image to come from the request
                sidebarImage = null;

                // call the function to make a request that will give us search info to populate the page
                this.getSearchInfo(this.props.searchID);

                // since we are waiting for info, we just indicate we want to show the loader
                queriedResults = 'show-loader';
            } else {
                // if we are here, we have gathered initial search info

                // if we weren't able to gather info from the API (API is most likely down) 
                if (!(this.state.runningAPI)) {

                    // instead of showing results, set the infinite scroller (the results div) to an API down message
                    // that allows the user to retry accessing the API
                    queriedResults = apiDownSplash;
                    hasMore = false;
                } else {
                    // if we are here, we have gathered initial search info and the API is running
                    sidebarImage = this.state.searchInfo.image_path;
                    let coords = this.state.coordinates;
                    window.map.panTo({lat: coords[0], lng: coords[1]});
                    window.map.setZoom(this.state.mapZoom);
                    document.getElementById("keywords-input").value = this.state.keywords;
                    if(!this.state.mapBounds) {
                        let boundsObj = window.map.getBounds();
                        let bounds = {
                            'ne': [parseFloat(boundsObj.getNorthEast().lat()), parseFloat(boundsObj.getNorthEast().lng())],
                            'sw': [parseFloat(boundsObj.getSouthWest().lat()), parseFloat(boundsObj.getSouthWest().lng())],
                        }
                        this.setState({ mapBounds: bounds });
                    };
                    // if the filter is active (someone has a value entered in the filter input), filter results
                    if (this.state.filterActive) {
                        // turn the array of search results into renderable content. The second argument is the filter
                        // query
                        queriedResults = this.mapResults(this.state.searchResults, this.state.filterQuery);

                        // we set hasMore to false because when we view filtered results, we only filter the loaded
                        // results
                        hasMore = false;

                        // if no results match the filter, display the below error message
                        if (!(queriedResults.length)) {
                            queriedResults = 'No matching results found';
                        }
                    } else {
                        // turn the array of search results into renderable content.
                        queriedResults = this.mapResults(this.state.searchResults);
                    }

                }

            }


            ////////////////
        } else {
            ////////////////
            if (this.state.runningAPI) {
                if (this.state.isLoading && this.state.resultSet === 0) {
                    queriedResults = 'show-loader';
                } else if (this.state.filterActive) {
                    queriedResults = this.mapResults(this.state.searchResults, this.state.filterQuery);
                    hasMore = false;

                    if (!(queriedResults.length)) {
                        queriedResults = 'No matching results found';
                    }
                } else {
                    queriedResults = this.mapResults(this.state.searchResults);
                }
            } else {
                queriedResults = apiDownSplash;
                hasMore = false;
            }

        }
        // Check first to see if we've aborted a pending request.
        // without it, this goes haywire and creates an infinite loop of broken react renders
        if (!this.controller.signal.aborted) {
            if (queriedResults === 'show-loader') {
                infiniteScroller = loader;
            } else {
                infiniteScroller = (
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={this.loadData.bind(this)}
                        hasMore={!this.state.isLoading && this.state.hasMore}
                        // loader is appearing off-page. Custom loader implemented with loading-more-results div
                        loader={<div className="loads" key={0}> Loading... </div>}
                        useWindow={false}
                        threshold={window.innerHeight}
                        getScrollParent={() => this.scrollParentRef}
                        className="infinite-scroller"
                    >
                        {queriedResults}

                        {hasMore || isLoading ? '' : resultsEnd}
                    </InfiniteScroll >
                );
            }
        } else {
            // controller.signal.aborted is read only. In order to re-set it, we create a new controller
            this.setState({ abortedRequest: true });
            this.controller = new AbortController();
            queriedResults = 'show-loader';
            infiniteScroller = loader;
        }

        pageContents = (
            <div className="search-page-content scroll">
                <OptionBar
                    download={true}
                    locked={false}
                    searchID={this.props.searchID}
                    sidebarImgUrl={sidebarImage}
                    rep={() => {
                        this.setState({ reportGen: true });
                    }}
                />
                <SideBar
                    imgUrl={sidebarImage}
                    imgStyles={{
                        filter: `brightness(${this.state.brightness}%)
                                     contrast(${this.state.contrast}%)
                                     saturate(${this.state.saturation}%)`
                    }}
                    editImage={true}

                    resultsPage={true}

                    locked={false}

                    location={this.state.location}
                    coordinates={this.state.coordinates}
                    keywords={this.state.keywords}
                    mapZoom={this.state.mapZoom}

                    resetSearch={true}
                    resetSearchFunc={this.resetSearch.bind(this)}
                    updateSearch={(updateSearchObj) => {
                        this.updateSearch(updateSearchObj)
                    }}
                />
                {reportGen}
                <div className="interaction-pane show-results" id="show-results-pane"
                    ref={(ref) => this.scrollParentRef = ref}>
                    <div className="results-top-label">

                    <ResultsSearchBar 
                        filterResults={(filterQuery) => {
                            if (filterQuery) {
                                this.setState({
                                    filterActive: true,
                                    filterQuery: filterQuery,
                                    filter_keywords: filterQuery
                                }, this.triggerDataLoad); // Using triggerDataLoad after state updates
                            } else {
                                this.setState({
                                    filterActive: false,
                                    filterQuery: null,
                                    filter_keywords: null
                                });
                            }
                        }}
                    />
                    </div>
                    {this.state.filterActive ?
                        <div className="results-filter-indicator-wrapper">
                            <div className="results-filter-indicator">
                                <div className="disable-filter"
                                onClick={() => {
                                    document.getElementById('results-search-bar-input').value = '';
                                    this.setState({
                                        filterActive: false,
                                        filterQuery: null
                                    });
                                    this.loadData(false); // rerun the search without the filter terms
                                }}
                                >Filter Active       
                                    <FontAwesomeIcon icon="times"
                                        className="disable-filter-icon" />
                                </div>
                            </div>
                            
                        </div>
                        : ""
                    }


                    {this.state.reportResults.length ?
                        <div className="report-clear-wrapper">
                            <div className="report-item-count">
                                {
                                    this.state.reportResults.length === 1 ?
                                        `${this.state.reportResults.length} Item in report` :
                                        `${this.state.reportResults.length} Items in report`
                                }
                            </div>
                            <div className="report-clear"
                                onClick={() => {
                                    let confirmDelete = window.confirm('Are you sure you wish to erase the contents of this report?');
                                    if (confirmDelete === true) {
                                        this.deconstructReport();
                                    }
                                }}
                            >
                                Clear Report
                                    <FontAwesomeIcon className="report-clear-icon" icon="times" />
                            </div>
                        </div>
                        : ""
                    }
                    {infiniteScroller}
                    {this.state.isLoading && this.state.resultSet > 0 ?
                        <FontAwesomeIcon icon="circle-notch"
                            className="fa-spin loading-more-results" /> : ""
                    }
                </div>
            </div >);

        return pageContents;
    }

    toggleSearchEdit() {
        return;
    }

    resetSearch() {
        this.setState({
            reset: true
        });
    }
    showReport(info) {
        // this.setState({
        //     // currentPage: 'report',
        //     // searchID: this.props.searchID,
        //     // imageUrl: this.state.imgURL,
        //     // sidebarImgUrl: props.sidebarImgUrl
        // });
        // let info = {
        //   searchID: this.props.searchID,
        //   imageURL: this.props.imgURL,
        //   sidebarImgUrl: this.props.sidebarImgUrl,
        // };
        localStorage.setItem("info", JSON.stringify(info));
        // route to new page by changing window.location
        window.open(`/report/${this.props.searchID}`, "_blank");
    }
}

class ResultsPageBuilder extends PageBuilder {

    async onPageLoad() {
        return;
    }

    // @override
    pageContent() {
        if (this.props.urlAccessed && this.props.searchID) {
            return (
                <ResultsPage searchID={this.props.searchID}
                    urlAccessed={true} />
            );
        } else if (!this.props.urlAccessed && this.props.imgUrl) {
            return (
                <ResultsPage />
            );
        } else {
            window.history.pushState({}, 'search', '/search');
            return (
                //~ window.alert("We need more information to load this page. Please start a new search or provide a search ID")
                <SearchPage />
            );
        }

    }

}

export {
    ResultsPage,
    ResultsPageBuilder
};
