import React, {
    Component
} from 'react';
import './ErrorPage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';

class ErrorPage extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        let contents = '';
        if (this.props.status === "invalid_account") {
            contents = (
                <div>
                    <p>We could not find that account. Contact us if you would like to create an account, or if you believe this is a mistake.</p>
                    <div className='link'><a href="/contact">Contact us</a></div>
                    <div className='link'><a href="/">Home</a></div>
                </div>
            )
        }
        else if (this.props.status === "not_verified") {
            contents = (
                <div>
                    <p>We were not able to verify your Google Account. Please make sure that your email address is verified and try again. If the problem persists, please contact us.</p>
                    <div className='link'><a href="/login">Try Login Again</a></div>
                    <div className='link'><a href="/contact">Contact us</a></div>
                    <div className='link'><a href="/">Home</a></div>
                </div>
            )
        }
        else if (this.props.status === "perm_error") {
            contents = (
                <div>
                    <p>This page requires administrator permissions. Contact us if you believe this is a mistake.</p>
                    <div className='link'><a href='/contact'>Contact us</a></div>
                    <div className='link'><a href='/'>Home</a></div>
                </div>
            )
        }
        else {
            contents = (
                <div>
                    <p>Page not found</p>
                    <div className='link'><a href="/">Home</a></div>
                </div>
            );
        }
        return (
            <div className='error-page-content'>
                <div className='error-page-wrapper'>
                    <h1>Error</h1>
                    {contents}
                </div>
            </div>
        );
    }
}

class ErrorPageBuilder extends PageBuilder {

    // @override
    async onPageLoad() {
        return;
    }

    // @override
    pageContent() {
        return (
            <ErrorPage status={this.props.status} />
        );
    }

}

export {
    ErrorPage,
    ErrorPageBuilder
};
