import React, {
    Component
} from 'react';
import './HotelPage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';

import HotelPageSideBar from '../../components/HotelPageSideBar/HotelPageSideBar.js';
import HotelPageResult from '../../components/HotelPageResult/HotelPageResult.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroller';
import { ErrorPage } from '../ErrorPage/ErrorPage';


class HotelPage extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.results = [];
        this.state = {
            results: [],
            pageNum: 1,
            //resultSet and resultSize are in here to prepare for API shift away from pagination. currently dont do anything.
            resultSet: 0,
            resultSize: 30,
            newPage: false,
            refreshed: true,
            hasMore: true,
            googlePlacesLoaded: false,
            activeModalId: null
        }
    }

    async loadData(append) {
        let passed = true;
        let encodedHotelID = encodeURI(this.props.hotelID);
        const rawResponse = await fetch(`/api/hotel_images/${encodedHotelID}?n_results=${this.state.resultSize}&offset=${this.state.resultSet * this.state.resultSize}&page=${this.state.pageNum}`, {
            method: 'GET',
            

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).catch(() => {
            passed = false;
        });

        if (passed) {
            const content = await rawResponse.json();
            let results = content.images;
            let hotelImageCount = this.props.hotelInfo.image_count;
            // function shuffle(array) {
            //     array.sort(() => Math.random() - 0.5);
            // }
            //
            // shuffle(results);


            let copiedOld = this.state.results.slice();
            let combinedResults = copiedOld.concat(results);

            let hasMore = this.state.hasMore;

            if (combinedResults.length + 1 >= hotelImageCount) {
                hasMore = false;
            }

            this.setState({
                results: combinedResults,
                hasMore: hasMore,
                resultSet: this.state.resultSet + 1,
                pageNum: this.state.pageNum + 1,
            });
        };

        return;
    }

    toggleModal(resultID= null){

        //get our elements
        let activeWrapper = document.getElementById(`hp-result-wrapper-${this.state.activeModalId}`);
        let activePanel = document.getElementById(`hp-${this.state.activeModalId}-panel`);
        let activeShifter = document.getElementById(`${this.state.activeModalId}-shifter`);
        let selectedWrapper = document.getElementById(`hp-result-wrapper-${resultID}`);
        let selectedPanel = document.getElementById(`hp-${resultID}-panel`);
        let selectedShifter = document.getElementById(`${resultID}-shifter`);
            
        if(resultID && resultID != this.state.activeModalId){
            
            if (activeWrapper && activePanel && activeShifter) {
                //hide the currently active modal
                activeWrapper.classList.toggle('focus');
                activePanel.classList.toggle('show');
                activeShifter.classList.toggle('show');
            }

            if (selectedWrapper && selectedPanel && selectedShifter){
                //activate the newly selected modal
                selectedWrapper.classList.toggle('focus');
                selectedPanel.classList.toggle('show');
                selectedShifter.classList.toggle('show');

                //scroll into place
                document.getElementById('hotel-results').scrollTop = selectedWrapper.offsetTop - 10;

                //not sure what this does but its a leftover from old code. Not tryna break anything. Try deleting this sometime.
                var evt = new Event("keydown", { "bubbles": true, "cancelable": false });
                selectedPanel.dispatchEvent(evt);
            }
            //record new selection in component state
            this.setState({
                activeModalId: resultID,
            });
        } else {
            if (activeWrapper && activePanel && activeShifter) {
                // method passed null as argument
                // close active modal
                activeWrapper.classList.toggle('focus');
                activePanel.classList.toggle('show');
                activeShifter.classList.toggle('show');

                this.setState({
                    activeModalId: null,
                });
            };
        };
    };

    mapResults() {
        let results = this.state.results;
        return results.map((x, i) => {
            //alert(results[i + 1]);
            let prevID = null;
            let nextID = null;

            if (i !== 0) {
                //     prevID = `${results[i - 1].id}`;
                // }
                //
                // if (i !== results.length - 1) {
                //     nextID = `${results[i + 1].id}`;
                prevID = `${results[i - 1]['id']}`;
            }

            if (i !== results.length - 1) {
                nextID = `${results[i + 1]['id']}`;
                //alert(i);
            }

            // if (i !== 0) {
            //     prevID = `${x.id}-${i - 1}`;
            // }
            //
            // if (i !== results.length) {
            //     nextID = `${x.id}-${i + 1}`;
            // }
            return (
                <HotelPageResult
                    imgUrl={x.path}
                    hotelID={x.hotel_id}
                    hotelName={x.name}
                    id={`${x.id}`}
                    key={`${x.id}`}
                    room={x.room}
                    prevID={prevID}
                    nextID={nextID}
                    toggleModal = {this.toggleModal.bind(this)}
                />
            );
        });
    }

    render() {

        if (this.state.hotelNotFound) {
            return <ErrorPage status={404} />;
        }
        let imageLoad = (
            <FontAwesomeIcon icon="circle-notch"
                className="fa-spin hotel-page-load" key={'loader'} />
        )
        let imageResults = this.mapResults();

        let resultsEnd = (
            <div className="hp-results-end">
                End of hotel images
            </div>
        );

        return (
            <div className="hotel-page-content" >
                <HotelPageSideBar hotelName={this.props.hotelInfo.name}
                    hotelLocation={`${this.props.hotelInfo.lat}, ${this.props.hotelInfo.lng}`}
                    imgCount={this.props.hotelInfo.image_count}
                    address={this.props.hotelInfo.address}
                    phoneNumber={this.props.hotelInfo.phone_number}
                    website={this.props.hotelInfo.website} />
                <div className="hotel-results" id="hotel-results">
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={this.loadData.bind(this)}
                        hasMore={this.state.hasMore}
                        loader={imageLoad}
                        threshold={window.innerHeight}
                        useWindow={false}
                    >
                        {imageResults}
                    </InfiniteScroll >
                    {this.state.hasMore ? '' : resultsEnd}
                </div>
            </div>);
    }
}

class HotelPageBuilder extends PageBuilder {
    hotelInfo = {}

    async onPageLoad() {
        let encodedHotelID = encodeURI(this.props.hotelID);
        const rawResponse = await fetch(`/api/hotel_info/${encodedHotelID}`, {
            method: 'GET',

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        try {
            const content = await rawResponse.json();

            this.hotelInfo = content;
            this.hotelNotFound = false;
        } catch (error) {
            this.hotelNotFound = true;
        }

    }

    // @override
    pageContent() {
        if (this.hotelNotFound) {
            return <ErrorPage status={404} />;
        } else {
            return (
                <HotelPage hotelID={this.props.hotelID}
                    hotelInfo={this.hotelInfo} />
            );
        }

    }

}

export {
    HotelPage,
    HotelPageBuilder
};
